<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-button
          variant="primary"
          @click="print"
        >
          print
        </b-button>
      </b-card>
    </b-col>
    <b-col class="text-center">
      <b-card id="printMe">

        <div style="width: 454px!important; height: 791px; background-color: white;">
          <div
            style="
          width: 170px!important;
            height: 120px;
            position: fixed;
            margin-top: 0px;
            margin-left: 10px;
            "
          >
            <img
              src="@/assets/images/logo/logo.jpg"
              style="width: 80px;
            height: 86px;"
            >
            <label style=" color: black; text-align: left; padding-left: 25px;">{{ currentDateTime() }} : التاريخ </label>
          </div>

          <label
            style="
              margin-bottom:0px!important;margin-left: 150px;margin-right: 0px!important; text-align: center;
               color: red; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';
                 font-size: 20px;"
          >وصل قبض
            <br> <label
              style="border: 2px black solid;
                  color: black; font-weight: bolder;
                  padding:0px 55px;
                  margin-top: 20px;
                   font-family: 'Calibri'; font-size: 14px;"
            >رقم : 8589
            </label>
          </label>
          <label
            style="text-align: end;
              float: right; font-size: 20px;
              font-weight: bold;margin-right: 0px!important;
font-family: 'Adobe Arabic"
          >النقل <br>
            إدارة استيراد<br> السيارات<br>
            قسم التراخيص<br>
          </label>
          <div style="position:absolute; margin-top: 50px;">
            <table>
              <tr style="border: 2px solid black;">
                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 173px;"
                >{{ check.checkin_data.chassis_number }}</td>

                <td
                  style="text-align: center; color: #0d6aad; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
                >رقم الشاصيه :</td>
                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
                >{{ check.plate_data.number }}</td>
                <td
                  style="text-align: center; color: #0d6aad; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 90px;"
                >رقم اللوحة:</td>
              </tr>
              <tr>
                <td
                  style="text-align: center; color: #8a3f43; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
                > {{ check.ebroker_data.name }}</td>
                <td
                  colspan="3"
                  style="text-align: center; color: #8a3f43; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
                />
              </tr>
              <tr style="border: 2px solid black;">
                <td
                  colspan="2"
                  style="text-align: center; color: #0d6aad; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >فقط لا غير</td>

                <td
                  style="text-align: center; color: #0d6aad; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >المبلغ</td>

                <td
                  style="text-align: center; color: #0d6aad; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >البيان</td>
              </tr>
              <tr style="border: 2px solid black;">
                <td
                  colspan="2"
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >فقط {{ $t(check.plate_fee) }} دولار</td>

                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >{{ check.plate_fee }} $</td>

                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >رسم تنمير</td>
              </tr>

              <tr style="border: 2px solid black;">

                <td
                  colspan="2"
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >فقط {{ $t(check.import_fee) }} دولار</td>
                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >{{ check.import_fee }}$</td>
                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >إذن استيراد</td>
              </tr>
              <tr style="border: 2px solid black;">
                <td
                  colspan="2"
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >فقط {{ $t(check.customs_fee) }} دولار</td>
                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >{{ check.customs_fee }} $</td>

                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >تخليص جمركي </td>
              </tr>
              <tr style="border: 2px solid black;">

                <td
                  colspan="2"
                  style="text-align: center; color: #8a3f43; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >فقط {{ $t(check.plate_fee + check.customs_fee + check.import_fee +check.renew_fee) }} دولار</td>
                <td
                  style="text-align: center; color: #8a3f43; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >{{ check.plate_fee + check.customs_fee + check.import_fee +check.renew_fee }} $</td>

                <td
                  style="text-align: center; color: #0d6aad; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                >الإجمالي</td>
              </tr>
              <tr>
                <td
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
                >أمين الصندوق</td>
                <td
                  colspan="3"
                  style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px"
                />

              </tr>
            </table>

            <div style="alignment: center; height: 70px">
              <label style="margin-left: 40%; font-weight: lighter; color: gray; text-align: center; font-size: 40px; font-family: 'Traditional Arabic'">صفحة 1</label>
            </div>
            <div style="alignment: center; height: 70px">
              <label style="margin-right: 60%; font-weight: bold; text-align: left!important; font-size: 18px; font-family: 'Traditional Arabic'">{{ check.car_data.manufacturer }}  {{ check.car_data.model }}</label>
              <label style=" font-weight: lighter; color: gray;  font-size: 18px; font-family: 'Traditional Arabic'">(النسخة الأصلية)</label>
              <hr style="margin-top: 0px!important;">
              <div style="width: 454px!important; height: 791px; background-color: white;">
                <div
                  style="
          width: 170px!important;
            height: 120px;
            position: fixed;
            margin-top: 0px;
            margin-left: 10px;
            "
                >
                  <img
                    src="@/assets/images/logo/logo.jpg"
                    style="width: 80px;
            height: 86px;"
                  >
                  <label style=" color: black; text-align: left; padding-left: 25px;">{{ currentDateTime() }} : التاريخ </label>
                </div>

                <label
                  style="
              margin-bottom:0px!important;margin-left: 150px;margin-right: 0px!important; text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';
                 font-size: 20px;"
                >وصل قبض
                  <br> <label
                    style="border: 2px black solid;
                  color: black; font-weight: bolder;
                  padding:0px 55px;
                  margin-top: 20px;
                   font-family: 'Calibri'; font-size: 14px;"
                  >رقم : 8589
                  </label>
                </label>
                <label
                  style="text-align: end;
              float: right; font-size: 20px;
              font-weight: bold;margin-right: 0px!important;
font-family: 'Adobe Arabic"
                >النقل <br>
                  إدارة استيراد<br> السيارات<br>
                  قسم التراخيص<br>
                </label>
                <div style="position:absolute; margin-top: 50px;">
                  <table>
                    <tr style="border: 2px solid black;">
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 173px;"
                      >{{ check.checkin_data.chassis_number }}</td>

                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
                      >رقم الشاصيه :</td>
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 95px;"
                      >{{ check.plate_data.number }}</td>
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black; width: 90px;"
                      >رقم اللوحة:</td>
                    </tr>
                    <tr>
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
                      > {{ check.ebroker_data.name }}</td>
                      <td
                        colspan="3"
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px;"
                      >  : المبالغ التالية  {{ check.owner_data.name }}   تم القبض من السيد    </td>
                    </tr>
                    <tr style="border: 2px solid black;">
                      <td
                        colspan="2"
                        style="text-align: center; color: black; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >فقط لا غير</td>

                      <td
                        style="text-align: center; color: black; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >المبلغ</td>

                      <td
                        style="text-align: center; color: black; font-family: 'Hacen Newspaper'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >البيان</td>
                    </tr>
                    <tr style="border: 2px solid black;">
                      <td
                        colspan="2"
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >فقط {{ $t(check.plate_fee) }} دولار</td>

                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >{{ check.plate_fee }} $</td>

                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >رسم تنمير</td>
                    </tr>

                    <tr style="border: 2px solid black;">

                      <td
                        colspan="2"
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >فقط {{ $t(check.import_fee) }} دولار</td>
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >{{ check.import_fee }}$</td>
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >إذن استيراد</td>
                    </tr>
                    <tr style="border: 2px solid black;">
                      <td
                        colspan="2"
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >فقط {{ $t(check.customs_fee) }} دولار</td>
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >{{ check.customs_fee }} $</td>

                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >تخليص جمركي </td>
                    </tr>
                    <tr style="border: 2px solid black;">

                      <td
                        colspan="2"
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >فقط {{ $t( check.plate_fee + check.customs_fee + check.import_fee +check.renew_fee) }} دولار/الصافي  {{ check.plate_fee + check.import_fee +check.renew_fee }} </td>
                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >{{ check.plate_fee + check.customs_fee + check.import_fee +check.renew_fee }} $</td>

                      <td
                        style="text-align: center; color: black; font-family: 'Traditional Arabic'; font-weight: bold;
             font-size: 14px; border: 2px solid black;"
                      >الإجمالي</td>
                    </tr>
                  </table>
                  <div style="alignment: center; height: 70px">
                    <label style="margin-right: 60%; font-weight: bold; text-align: left!important; font-size: 18px; font-family: 'Traditional Arabic'">{{ check.car_data.manufacturer }}  {{ check.car_data.model }}</label>
                    <label style=" font-weight: lighter; color: gray;  font-size: 18px; font-family: 'Traditional Arabic'">(نسخة القسم)</label>

                  </div>

                </div>
              </div>

            </div>

          </div>
        </div></b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    check() {
      return this.$store.getters['check/GetElement']
    },
  },
  created() {
    this.$store.dispatch('check/GetElement', this.$route.params.check)
  },
  methods: {
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
      const dateTime = `${date}`

      return dateTime
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe')
    },
  },
}</script>
